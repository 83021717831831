.taskManager {
    background-color: #f3f2ed;
    width: 100%;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #333;
}

.taskManager--wrapper {
    padding: 0 1rem;
}

.taskManager--title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}

.taskManager--grid {
    display: grid;
    grid-gap: 1.5rem;
    padding: 1rem 0;
}

.taskManager--grid>div {
    background-color: #F5F5F5;
    border-radius: 0.5rem;
    padding: .5rem 1rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}