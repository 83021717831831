.taskManager--addTask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .2rem;
}

.addTask-icon {
  font-size: 1.2rem;
  margin-right: .2rem;
}

.taskManager--addTask__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
}

.addTask-btn {
  border: none;
  border-radius: 0.5rem;
  color: #f2f5f5;
  background-color: #00bfff;
  padding: .2rem .5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: none;
}

.addTask-btn:hover {
  background-color: #00bfff;
  transform: scale(1.1);
}

.addTask-btn:active {
  transform: scale(0.9);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.form--group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form--content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}

.form--error {
  color: red;
  font-weight: 700;
  font-size: .7rem;
  margin: 0.5rem 0 0 5rem;
}


.form--label {
  font-weight: 700;
  margin-right: 0.5rem;
  width: 20%;
  white-space: nowrap;
}

.form--input {
  width: 80%;
  padding: .5rem;
  border-radius: 0.5rem;
  font-family: "Fira Sans";
  color: #333;
  border: none;
  outline: none;
}

.form--description {
  width: 80%;
  padding: .5rem;
  border-radius: 0.5rem;
  font-family: "Fira Sans";
  color: #333;
  border: none;
  outline: none;
}

.form--select {
  width: 80%;
  padding: .5rem 0;
  border: none;
  border-radius: 0.5rem;
  font-family: "Fira Sans";
  color: #333;
}

.form--select:focus {
  outline: none;
}