.taskList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.taskList--head {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.taskList--empty {
  align-self: center;
  margin: auto auto;
  height: 100%;
  text-align: center;
}

.task {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}

.task--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  background-color: #f3f2ed;
  padding: .5rem 0;
}

.task--item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
  border-bottom: 1px solid #00bfff;
  padding-bottom: .5rem;
  text-align: center;
}

strong {
  font-weight: 700;
}

.task--item:last-child {
  border-bottom: none;
}

.task--btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 1rem 0;
}

.task--btn {
  border-radius: 1rem;
  background-color: #00bfff;
  color: #F5F5F5;
  cursor: pointer;
  padding: 0.3rem .7rem;
  border: none;
  outline: none;
}

.task--btn:hover {
  color: #00bfff;
  background-color: #f3f2ed;
}

.task--btn:active {
  color: #f3f2ed;
  background-color: #00bfff;
}

.edit--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
}

.edit--group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.edit--label {
  font-weight: 700;
  margin-right: 0.5rem;
}

.edit--input {
  padding: .5rem;
  border-radius: 0.5rem;
  font-family: "Fira Sans";
  color: #333;
  border: none;
  outline: none;
}

.edit--input:focus {
  outline: none;
}

.edit--description {
  padding: .5rem;
  border-radius: 0.5rem;
  font-family: "Fira Sans";
  color: #333;
  border: none;
  outline: none;
}

.edit--select {
  padding: .5rem 0;
  border: none;
  border-radius: 0.5rem;
  font-family: "Fira Sans";
  color: #333;
  outline: none;
  cursor: pointer;
}

.edit--select:focus {
  outline: none;
}

.edit--btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}

.edit--btn {
  border-radius: 1rem;
  background-color: #00bfff;
  color: #F5F5F5;
  cursor: pointer;
  padding: 0.3rem .7rem;
  border: none;
  outline: none;
}

.edit--btn:hover {
  color: #00bfff;
  background-color: #f3f2ed;
}