.view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.view--head {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.view--wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.view--lists {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  width: 100%;
  padding: 1rem 0;
}

.view--list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.view--categoryName {
  font-weight: 700;
  margin-right: 0.5rem;
  text-decoration: none;
  color: #f2f2f5;
  width: 80%;
  height: 5rem;
  background-color: #00bfff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.category--head {
  font-size: 1.5rem;
  font-weight: 700;
}

.category--empty {
  text-align: center;
  margin: auto auto;
  height: 100%;
}

.emptyText {
  font-size: 1rem;
  font-weight: 400;
}

.emptySubMsg {
  margin-top: -0.5rem;
}

.category--lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
}

.category--list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.category--item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
  border-bottom: 1px solid #00bfff;
  padding-bottom: .5rem;
}

.category--item:last-child {
  border-bottom: none;
}

.category--link {
  text-decoration: none;
  color: #f2f2f5;
  background-color: #00bfff;
  border-radius: 0.5rem;
  padding: .5rem;
  margin-top: -1.5rem;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.category--link:active {
  transform: scale(0.95);
}

.category--link:hover {
  color: #00bfff;
  background-color: #f2f2f5;
}


@media screen and (max-width: 900px) {
  .view--lists {
    grid-template-columns: repeat(2, 1fr);
  }
}